import { call, put, takeEvery } from "redux-saga/effects";
import { airAwardBookingRequest, airAwardBookingSuccess, airAwardBookingFailure } from "../slice/airAwardBookingSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config === null || config === void 0 ? void 0 : config.AIR_AWARD_BOOKING_API;
const fetchAirAwardBookingDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true,
    isGenericErr: true
  };
  let response;
  if (!(payload !== null && payload !== void 0 && payload.isStarAlliance)) {
    response = await api.post(API_URL, payload, headerData);
  } else {
    let starURL = (config === null || config === void 0 ? void 0 : config.AIR_AWARD_BOOKING_API) + "?starAlliance=true";
    response = await api.post(starURL, payload, headerData);
  }
  return response;
};
function* fetchAirAwardBooking(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardBookingDetail, payload);
    yield put(airAwardBookingSuccess(response));
  } catch (error) {
    yield put(airAwardBookingFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchAirAwardBooking() {
  yield takeEvery(airAwardBookingRequest.type, fetchAirAwardBooking);
}